var jQuery = require("jquery");
import Parallax from 'parallax-js';
import Glide, {Controls,Swipe} from '@glidejs/glide/dist/glide.modular.esm';
import '@fancyapps/fancybox';

$(document).ready(function () {

    //smooth scroll
    $(".smoothScroll").on('click', function (e) {
        if (this.hash !== '') {
            e.preventDefault();
            var hash = this.hash;
            var height = window.innerHeight;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 300, function () {
                window.location.hash = hash;
            })
        }
    });

    $('.preloader').remove();

    var body = $('body');

    $('.js-triggerOverlay').each(function (e) {
        $(this).on('click', function (elem) {
            let overlay = '.' + $(this).data('overlay');
            elem.preventDefault();
            $(overlay).addClass('overlay-active');
            body.css('overflow', 'hidden');
            body.css('height', '100%');
        })
    });

    $('.js-closeOverlay').each(function (e) {
        $(this).on('click', function (elem) {
            elem.preventDefault();
            $('.overlay-active').removeClass('overlay-active');
            body.css('overflow', '');
            body.css('height', '');
        })
    });

    $('#home').find('.scene').each(function (index, scene) {
        new Parallax(scene, {
            selector: '.layer',
            limitX: 10,
            limitY: 10,
            scalarX: 2,
            scalarY: 8,
            frictionX: 0.2,
            frictionY: 0.8
        });
    });

    //Glide JS
    var elements = document.querySelectorAll('.glide');
    elements.forEach(function (e) {
        let glide = new Glide(e, {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            autoplay: 1000,
            gap: 0,
        });
        glide.mount({Controls,Swipe});
    });
});